<template>
  <div>
    <div class="mb-4">
      <h1 class="mb-2">
        {{ t(titleKey) }}
      </h1>
      <div class="d-flex flex-wrap ga-4">
        <slot name="actions" :reload="reload"></slot>
      </div>
    </div>
    <v-data-table-server
      v-model:items-per-page="itemsPerPage"
      class="w-100"
      :headers="headers"
      :items="formattedItems"
      :items-length="totalItems"
      :loading="loading"
      :cell-props="cellProps"
      :mobile="isMobile"
      :data-cy="dataCy"
      @update:options="optionsChanged"
    >
      <!--
         Dynamic slots in data table cause issue with inheriting types from dynamic slot collection. 
         Probably we can try to do some ts magic, but is not worth of invedted time. 
        -->
      <!-- @vue-skip -->
      <template
        v-for="colItem of itemSlots"
        #[`item.${colItem.key}`]="itemProps"
        :key="colItem.key"
      >
        <template v-if="colItem.component">
          <component
            :is="colItem.component"
            :config="colItem.componentConfig"
            :col-key="itemProps.column.key!"
            :row-item="itemProps.item"
            :reload="reload"
          />
        </template>
      </template>

      <template #[`item.actions`]="action">
        <div class="d-flex justify-end ga-2">
          <slot
            name="item-actions"
            :item="getRawItem(action.item)"
            :reload="load"
          ></slot>
        </div>
      </template>
      <template #bottom>
        <div class="pt-8">
          <v-pagination
            v-model="currentPage"
            :length="totalPages"
            class="w-100"
            active-class="active-page"
            density="compact"
          />
        </div>
      </template>
    </v-data-table-server>
  </div>
</template>

<script setup lang="ts" generic="T">
import { get } from 'lodash';
import { useDateUtils } from '@/composables/useDateUtils';
import type {
  TableItem,
  TableLoadItemsOptions,
  TableOptions,
} from '@/types/table';
import { DEFAULT_ITEMS_PER_PAGE, TableColumnType } from '@/constants/table';
import { SnackbarType } from '@/constants/snackbarType';
import type TableCellChipList from '@/components/table/cell/TableCellChipList.vue';

interface Column {
  key: string;
  titleKey: string;
  type?: keyof typeof TableColumnType;
  mapper?: (item: T, key: string) => string;
  component?: typeof TableCellChipList;
}

interface Props {
  titleKey: string;
  columns: Column[];
  cellProps?: Record<string, unknown>;
  loadItems: (options: TableLoadItemsOptions) => Promise<{
    ok: boolean;
    items: T[];
    total: number;
  }>;
  dataCy?: string;
}

const itemsPerPage = ref(DEFAULT_ITEMS_PER_PAGE);

const props = defineProps<Props>();

const snackbar = useSnackbar();
const { t } = useI18n();
const { formatTimeSpan, formatDateTime, formatDate } = useDateUtils();
const { isMobile } = useCssBreakpoints();

const items = ref<T[]>([]) as Ref<T[]>;
const currentPage = ref(1);
const loading = ref(false);
const totalItems = ref(0);
const sortBy = ref<string | null>(null);
const isSortAscending = ref<boolean | null>(null);

const formattedItems = computed<T[]>(() => {
  return items.value.map((item) => {
    const formattedItem = { ...item } as TableItem<T>;

    props.columns.forEach(({ key, type, mapper }) => {
      const value = get(item, key);
      if (type === TableColumnType.datetime && typeof value == 'string') {
        formattedItem[key] = formatDateTime(value);
      } else if (type === TableColumnType.date && typeof value == 'string') {
        formattedItem[key] = formatDate(value);
      } else if (
        type === TableColumnType.timeSpan &&
        typeof value == 'string'
      ) {
        formattedItem[key] = formatTimeSpan(value);
      } else if (mapper) {
        formattedItem[key] = mapper(item, key);
      } else {
        formattedItem[key] = value;
      }
    });

    return formattedItem as T;
  });
});

const getRawItem = (tableItem: T) =>
  items.value.find((item) => get(item, 'id') == get(tableItem, 'id')) as T;

const headers = computed(() => {
  return props.columns.map((c) => ({
    value: c.key,
    title: c.titleKey ? t(c.titleKey) : '',
    ...c,
  }));
});

const totalPages = computed(() => {
  return Math.ceil(totalItems.value / itemsPerPage.value);
});

const slots = useSlots();
const itemSlots = computed(() =>
  props.columns.filter(
    (col) => Object.keys(slots).includes(`item.${col.key}`) || col.component,
  ),
);

const load = async () => {
  loading.value = true;
  const {
    ok,
    items: data,
    total,
  } = await props.loadItems({
    offset: (currentPage.value - 1) * itemsPerPage.value,
    limit: itemsPerPage.value,
    sortBy: sortBy.value,
    isSortAscending: isSortAscending.value,
  });
  loading.value = false;

  if (!ok) {
    snackbar.add({
      type: SnackbarType.error,
      text: t('error.failedToLoadItems'),
    });
    return;
  }

  items.value = data;
  totalItems.value = total;
};

const optionsChanged = (options: TableOptions) => {
  if (options.sortBy.length > 0) {
    sortBy.value = options.sortBy[0].key;
    isSortAscending.value = options.sortBy[0].order === 'asc';
  } else {
    sortBy.value = null;
    isSortAscending.value = null;
  }
};

load();
const reload = () => {
  currentPage.value = 1;
  load();
};

watch(currentPage, () => {
  load();
});

watch(sortBy, (val, prevVal) => {
  if (val !== prevVal) {
    load();
  }
});

watch(isSortAscending, (val, prevVal) => {
  if (val !== prevVal) {
    load();
  }
});

defineExpose({
  reload,
});
</script>

<style lang="scss" scoped>
.v-data-table {
  :deep(.v-data-table__th),
  :deep(.v-data-table__td-title) {
    font-weight: 600;
  }
}
</style>
