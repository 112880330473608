<template>
  <Select
    v-bind="$attrs"
    :items="items"
    item-title="title"
    item-value="value"
  />
</template>

<script setup lang="ts">
import type { VSelect } from 'vuetify/components';

// sfc-compiler has problem with inheriting complex types
// from libraries, therefor we need to use following ignore statement
type SelectProps = InstanceType<typeof VSelect>['$props'];
interface Props extends /* @vue-ignore */ SelectProps {
  chip?: boolean;
}

withDefaults(defineProps<Props>(), { chip: false });

const { t } = useI18n();

const items = [
  { title: t('common.active'), value: true },
  { title: t('common.inactive'), value: false },
];
</script>
